import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { InformationTooltip } from '../tooltips';
import HeaderStack from '../stacks/HeaderStack';

const InformativeHeader = ({
  title,
  subtitle,
  showGoBack,
  informativeText,
}) => (
  <HeaderStack showGoBack={showGoBack}>
    <Box>
      <Typography variant="h1" color="primary.main">
        {title}
      </Typography>
      {subtitle && <Typography variant="headerSubtitle">{subtitle}</Typography>}
    </Box>
    {informativeText && (
      <InformationTooltip title={informativeText} />
    )}
  </HeaderStack>
);

InformativeHeader.propTypes = {
  title: PropTypes.string.isRequired,
  showGoBack: PropTypes.bool,
  subtitle: PropTypes.string,
  informativeText: PropTypes.string,
};

InformativeHeader.defaultProps = {
  showGoBack: false,
  subtitle: null,
  informativeText: null,
};

export default InformativeHeader;
