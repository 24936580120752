import React from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const InfoGrid = ({ name, value }) => (
  <Grid container direction="row" width="100%">
    <Grid item xs={3}>
      <Typography variant="body2" noWrap>
        {name}:
      </Typography>
    </Grid>
    <Grid item xs={9}>
      <Typography variant="body2" noWrap>
        {value}
      </Typography>
    </Grid>
  </Grid>
);

InfoGrid.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default InfoGrid;
