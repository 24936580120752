import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import { Stack } from '@mui/system';
import { HeaderSearcherInput } from '../inputs';
import HelpVideo from './HelpVideo';
import JoyRideHelp from './JoyRideHelp';
import { InformationTooltip } from '../tooltips';
import { GoBackButton } from '../buttons';

const PaperHeader = ({
  viewTitle,
  finder,
  endComponent,
  intermediateComponent,
  helpVideo,
  openJoyride,
  informationTooltipText,
  showGoBack,
  ...props
}) => {
  const helpIcon = useMemo(() => {
    if (helpVideo) {
      return <HelpVideo helpVideo={helpVideo} />;
    }
    if (openJoyride) {
      return <JoyRideHelp openJoyRide={openJoyride} />;
    }
    if (informationTooltipText) {
      return <InformationTooltip title={informationTooltipText} />;
    }
    return null;
  }, [helpVideo, openJoyride, informationTooltipText]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={{ xs: 'center', md: 'flex-start' }}
      spacing={{ xs: 0, md: 1 }}
      mb={2}
      {...props}
    >
      {showGoBack && (
      <GoBackButton />
      )}
      <Typography variant="h1" color="primary.main">
        {viewTitle}
      </Typography>
      <Box
        display={{
          xs: 'none',
          md: 'inherit',
        }}
      >
        {helpIcon}
      </Box>
      {intermediateComponent && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexGrow: 1 }}>{intermediateComponent}</Box>}
      {finder && (
        <Box ml="auto !important">
          <HeaderSearcherInput finder={finder} />
        </Box>
      )}
      {endComponent && <Box ml="auto !important">{endComponent}</Box>}
    </Stack>
  );
};

PaperHeader.propTypes = {
  viewTitle: PropTypes.string.isRequired,
  finder: PropTypes.shape({
    onFinderChange: PropTypes.func.isRequired,
    searchPlaceHolder: PropTypes.string.isRequired,
    finderValue: PropTypes.string.isRequired,
    canPasteExcel: PropTypes.bool,
    setColumnFilter: PropTypes.func,
  }),
  endComponent: PropTypes.element,
  intermediateComponent: PropTypes.element,
  helpVideo: PropTypes.string,
  openJoyride: PropTypes.func,
  informationTooltipText: PropTypes.string,
  showGoBack: PropTypes.bool,
};

PaperHeader.defaultProps = {
  finder: null,
  endComponent: null,
  intermediateComponent: null,
  helpVideo: null,
  openJoyride: null,
  informationTooltipText: null,
  showGoBack: false,
};

export default PaperHeader;
