import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

const ItemsGrid = ({ items, renderItem, xsValue, ...props }) => (
  <Grid container justifyContent="center" spacing={2} {...props}>
    {items.map((item) => (
      <Grid item key={item.id} xs={xsValue}>
        {renderItem(item)}
      </Grid>
    ))}
  </Grid>
);

ItemsGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  renderItem: PropTypes.func.isRequired,
  xsValue: PropTypes.number,
};

ItemsGrid.defaultProps = {
  xsValue: 4,
};

export default ItemsGrid;
