import React, { useMemo } from 'react';
import { Stack, Grid, Typography } from '@mui/material';
import propTypes from 'prop-types';
import { useSelectedCompany } from '../../hooks';

import UploadCreditLineDocumentsInput from './UploadCreditLineDocumentsInput';

const UploadDocumentsForm = ({ requiredDocuments }) => {
  const company = useSelectedCompany();

  const documentsList = useMemo(() => {
    const companyDocuments = company?.documents || [];

    const documentTypesAndYears = requiredDocuments.reduce((acc, documentType) => {
      const requiredYears = documentType.years;
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();

      if (requiredYears === null) {
      // Handle the case where years is null
        const key = `${documentType.code}`;
        acc[key] = {
          name: '',
          documentType: documentType.name,
          file: '',
          code: documentType.code,
          template: documentType.template,
          alreadyUploaded: companyDocuments.some((document) => (
            document.documentType === documentType.code
          )),
          year: null,
        };
      } else {
      // Create an array of required years
        const requiredYearsArray = Array.from(
          { length: requiredYears },
          (_, index) => currentYear - index,
        );

        // Add the combination of document type and year to the accumulator
        requiredYearsArray.forEach((year) => {
          const key = `${documentType.code}-${year}`;
          acc[key] = {
            name: '',
            documentType: documentType.name,
            file: '',
            code: documentType.code,
            template: documentType.template,
            alreadyUploaded: companyDocuments.some((document) => (
              document.documentType === documentType.code
            && document.documentDate
            && parseInt(document.documentDate.substring(0, 4), 10) === year
            )),
            year,
          };
        });
      }

      return acc;
    }, {});

    // Convert the object values back to an array
    return Object.values(documentTypesAndYears);
  }, [requiredDocuments, company?.documents]);

  return (
    <>
      <Stack spacing={1.5} sx={{ paddingTop: 2 }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={1.5}
          sx={{ mb: 1 }}
        >
          <Grid item xs={6}>
            <Typography variant="h2" width="70%" sx={{ mr: 1 }}>
              Documento:
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" width="15%">
              Adjuntar
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" sx={{ mr: 3 }}>
              Estado
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" sx={{ mr: 3 }}>
              Plantilla
            </Typography>
          </Grid>
        </Grid>
        {documentsList.map(({ documentType, code, alreadyUploaded, year, template }) => (
          <UploadCreditLineDocumentsInput
            key={year ? `upload-document-${documentType}-${year}` : `upload-document-${documentType}`}
            documentType={documentType}
            code={code}
            alreadyUploaded={alreadyUploaded}
            year={year}
            template={template}
          />
        ))}
      </Stack>
    </>
  );
};

UploadDocumentsForm.propTypes = {
  requiredDocuments: propTypes.arrayOf(propTypes.shape({
    name: propTypes.string.isRequired,
    code: propTypes.string.isRequired,
    years: propTypes.number.isRequired,
    template: propTypes.string,
  })).isRequired,

};

export default UploadDocumentsForm;
